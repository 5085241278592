@font-face {
font-family: "VodafoneLt";
src: local("VodafoneLt"), url("./assets/font_vdf/VodafoneLt.ttf") format("truetype");
}

@font-face {
font-family: "VodafoneRg";
src: local("VodafoneRg"), url("./assets/font_vdf/VodafoneRg.ttf") format("truetype");
}

@font-face {
font-family: "VodafoneRgBd";
src: local("VodafoneRgBd"), url("./assets/font_vdf/VodafoneRgBd.ttf") format("truetype");
}





body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #000000;
  /*background-image: url('./assets/images/videowall/background.png');*/
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  height: 100vh;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  display: none;
}

